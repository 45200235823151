export default class Util {
    constructor(args) {
        this.args = args;
    }


    pushToDataLayer(eventName, data) {
        if(dataLayer) {
            var oData = data || {};

            oData.event = eventName;
            oData.url = window.location.href;

            dataLayer.push(oData);

            console.log('Util.pushToDataLayer()', oData);
        }
    }


    /**
     * https://www.kevinleary.net/javascript-get-url-parameters/
     *
     * JavaScript Get URL Parameter
     *
     * @param String prop The specific URL parameter you want to retreive the value for
     * @return String|Object If prop is provided a string value is returned, otherwise an object of all properties is returned
     */
    getUrlParams(prop) {
        let params = {};
        let search = decodeURIComponent( window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ) );
        let definitions = search.split( '&' );

        definitions.forEach( function( val, key ) {
            let parts = val.split( '=', 2 );
            params[ parts[ 0 ] ] = parts[ 1 ];
        });

        return ( prop && prop in params ) ? params[ prop ] : params;
    }


    magnificPopup(selector) {
        selector = selector || '.u-popup-link';

        let $selector = $(selector);

        if($selector.length) {
            import(/* webpackChunkName: "[request]" */'magnific-popup')
                .then(component => {
                    $(selector).magnificPopup({ type: 'image' });
                });
        }
    }


    dotdotdot(args) {
        args = args || {};
        args.selector = args.selector || '.u-dotdotdot';

        let $this = $(args.selector);

        if($this.length) {
            let lineCount = parseInt( $this.data('lines') );
            let lineHeight = $this.css('line-height');

            // treat line height values
            if (lineHeight.toLowerCase().indexOf('px') > -1) {
                lineHeight = parseInt(lineHeight, 10);
            } else if (lineHeight.indexOf('%') > -1) {
                lineHeight = (parseInt(lineHeight, 10) / 100) * parseInt($this.css('font-size'), 10);
            } else {
                lineHeight = parseInt(lineHeight, 10) * parseInt($this.css('font-size'), 10);
            }

            import(/* webpackChunkName: "[request]" */'dotdotdot')
                .then(component => {
                    $this.dotdotdot({
                        height: (lineCount * lineHeight) + lineHeight / 3,
                        watch: true,
                        wrap: 'letter',
                        callback: function(isTruncated) {}
                    });
                });
        }
    }


    scrollToElement(selector, offset) {
        offset = offset || 0;

        var $e = $(selector);
        if ($e.length) {
            var body = $("html, body");
            body.stop().animate({ scrollTop: $e.offset().top - offset }, '500');
        }
    }


    adjustSelectWidth(el) {
        var arrowWidth = 20;
        var text = el.find("option:selected").text();

        var $test = $("<span>").html(text).css({
        	"font-size": el.css("font-size"),
            "visibility": "hidden"
        });

        $test.appendTo('body');
        var width = $test.width();
        $test.remove();

        el.width(width + arrowWidth);
    }


    getBootstrapVisibleMediaQuery() {
        let boostrapBreakpoints = ['xl', 'lg', 'md', 'sm', 'xs'];
        let query = 'xs';

        $('body').append('<div id="bootstrap-media-checker-wrap" style="position: fixed;bottom:0;right:0;"></div>');

        $.each(boostrapBreakpoints, (k, v) => {
            $('#bootstrap-media-checker-wrap').append('<div class="d-none d-' + v + '-block" style="width:1px;height:1px;background:transparent"></div>');

            if ($('#bootstrap-media-checker-wrap .d-' + v + '-block').is(':visible')) {
                $('#bootstrap-media-checker-wrap').remove();
                query = v;
                return false;
            }
        });

        return query;
    }


    // https://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling
    elementIsInView(element, fullyInView) {
        /* Added check - if element not empty */
        if (!element || !$(element).length) {
            return false;
        }

        const pageTop = $(window).scrollTop();
        const pageBottom = pageTop + $(window).height();
        const elementTop = $(element).offset().top;
        const elementBottom = elementTop + $(element).height();

        if (fullyInView === true) {
            return ((pageTop < elementTop) && (pageBottom > elementBottom));
        } else {
            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
        }
    }


    getScrollbarWidth() {
        let scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return scrollbarWidth;
    }


    getFormData(form) {
        const aData = $(form).serializeArray();
        let oData = {};

        for(let i = 0; i < aData.length; i++) {
            oData[aData[i].name] = aData[i].value;
        }

        // Inputs inside labels
        $(form).find('input[type=radio]:checked, input[type=checkbox]:checked').each(function() {
            if(typeof oData[$(this).prop('name')] === 'undefined') {
                oData[$(this).prop('name')] = $(this).val();
            }
        });
        
        return oData;
    }


    showFormErrors(form, errors) {
        let sErrors = `<li>${errors}</li>`;

        if(typeof errors === 'object') {
            console.log(errors);
            const oErrors = errors.response.data.errors;
            sErrors = `<li>There was an error submitting the form</li>`;
            
            if(oErrors) {
                const aErrors = Object.keys(oErrors).map(error => `<li>${oErrors[error].join('</li><li>')}</li>`);
                sErrors = aErrors.join('');
            } else if(errors.response.data.message) {
                sErrors = `<li>${errors.response.data.message}</li>`;
            }
        }

        $(form).find('.form-errors').html(`<ul>${sErrors}</ul>`);

        this.enableSubmit(form);
    }


    showFormSuccess(form, message) {
        $(form).find('.form-success').html(`<p>${message}</p>`);
    }


    disableSubmit(form) {
        const $btn = $(form).find('.btn[type=submit]');

        $btn.first().append('<i class="fas fa-spinner fa-spin ml-2"></i>');
        $btn.prop('disabled', true);
    }

    
    enableSubmit(form) {
        const $btn = $(form).find('.btn[type=submit]');

        $btn.first().find('.fa-spinner').remove();
        $btn.prop('disabled', false);
    }


    init() {
        for(let fn in this.args) {
            this[fn](this.args[fn]);
        }
    }
}