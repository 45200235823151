var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Date, quest) {function prettyDate(dateString){
{
pug_html = pug_html + "\u003C!--if it's already a date object and not a string you don't need this line:--\u003E";
var date = new Date(dateString);
var d = date.getDate();
var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
var m = monthNames[date.getMonth()];
var y = date.getFullYear();
return d+' '+m+' '+y;
}
}
function available(dateString) {
{
var startDate = new Date(dateString);
var now = new Date();
return now >= startDate;
}
}
pug_html = pug_html + (null == (pug_interp = require("./header.pug").call(this, locals)) ? "" : pug_interp) + "\u003Cdiv class=\"container c-quest\"\u003E\n\u003Cdiv class=\"account-subtitle\"\u003E\n\u003Ch3\u003EIntroduction\u003C\u002Fh3\u003E";
if (!(available(quest.masterclass_start_at))) {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-primary\" role=\"alert\"\u003E\n\u003Ci class=\"fas fa-info-circle\"\u003E\u003C\u002Fi\u003E This masterclass starts on " + (pug.escape(null == (pug_interp = prettyDate(quest.masterclass_start_at)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"main-content\"\u003E";
if (available(quest.masterclass_start_at)) {
if (quest.video != 'none') {
pug_html = pug_html + "\u003Cdiv class=\"course-video c-quest__video\"\u003E";
if (quest.video == 'CHVCFY5YHnM') {
pug_html = pug_html + "\u003Ciframe controls autoplay class=\"resp-iframe\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" src=\"https:\u002F\u002Fwww.youtube.com\u002Fembed\u002F" + (pug.escape(null == (pug_interp = quest.video) ? "" : pug_interp)) + "?modestbranding=1&rel=0\" frameborder=\"0\"\u003E\u003C\u002Fiframe\u003E";
}
else {
pug_html = pug_html + "\u003Ciframe src=\"https:\u002F\u002Fmedia.publit.io\u002Ffile\u002F" + (pug.escape(null == (pug_interp = quest.video) ? "" : pug_interp)) + ".html?player=&amp;adtag=\" scrolling=\"no\" style=\"border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute; overflow:hidden;\" allowfullscreen=\"\"\u003E\u003C\u002Fiframe\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
}
pug_html = pug_html + "\u003Cdiv class=\"course-content\"\u003E\n\u003Cp class=\"lead-paragraph\"\u003E" + (pug.escape(null == (pug_interp = quest.intro) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003Cdiv class=\"normal-paragraph c-quest__description\"\u003E" + (pug.escape(null == (pug_interp = quest.description) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if (quest.resources && quest.resources.length && available(quest.masterclass_start_at)) {
pug_html = pug_html + "\u003Ch3\u003EResources\u003C\u002Fh3\u003E\n\u003Cul class=\"list-unstyled mb-5\"\u003E";
// iterate quest.resources
;(function(){
  var $$obj = quest.resources;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var resource = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E\n\u003Ci class=\"fas fa-file-pdf\"\u003E\u003C\u002Fi\u003E\n\u003Ca href=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = resource.file) ? "" : pug_interp)) + "\" target=\"_blank\"\u003E" + (pug.escape(null == (pug_interp = resource.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\n\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var resource = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E\n\u003Ci class=\"fas fa-file-pdf\"\u003E\u003C\u002Fi\u003E\n\u003Ca href=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = resource.file) ? "" : pug_interp)) + "\" target=\"_blank\"\u003E" + (pug.escape(null == (pug_interp = resource.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\n\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
if (!(quest.users && quest.users.length && quest.users[0].pivot.started)) {
if (quest.weeks && quest.weeks.length) {
if (available(quest.masterclass_start_at)) {
pug_html = pug_html + "\u003Ca class=\"btn btn-lg btn-primary c-start-quest\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = quest.weeks[0].lessons[0].id) ? "" : pug_interp)) + "\" role=\"button\"\u003EStart Quest\u003C\u002Fa\u003E";
}
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
if (quest.weeks && quest.weeks.length && available(quest.start_at)) {
pug_html = pug_html + "\u003Cdiv class=\"chapter-controls\"\u003E\n\u003Cdiv class=\"chapter-left col-md-6\"\u003E\n\u003Cdiv class=\"chapter-content\"\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003Ca class=\"chapter-right col-md-6\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = quest.weeks[0].lessons[0].id) ? "" : pug_interp)) + "\"\u003E\n\u003Cdiv class=\"chapter-content\"\u003E";
if (quest.users && quest.users.length) {
pug_html = pug_html + "\u003Cp\u003E" + (pug.escape(null == (pug_interp = quest.weeks[0].lessons[0].name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003Ci class=\"fas fa-caret-right\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fa\u003E\n\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\"\u003E\n\u003Cdiv class=\"modal-dialog modal-lg\"\u003E\n\u003Cdiv class=\"modal-content\"\u003E\n\u003Cdiv class=\"modal-body\"\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";}.call(this,"Date" in locals_for_with?locals_for_with.Date:typeof Date!=="undefined"?Date:undefined,"quest" in locals_for_with?locals_for_with.quest:typeof quest!=="undefined"?quest:undefined));;return pug_html;};
module.exports = template;