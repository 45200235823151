import Util from './util';
import User from './user';

import fontawesome from '@fortawesome/fontawesome';

import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import faPinterest from '@fortawesome/fontawesome-free-brands/faPinterestP';
import faGooglePlus from '@fortawesome/fontawesome-free-brands/faGooglePlus';
import faStar from '@fortawesome/fontawesome-free-regular/faStar';
import faPrint from '@fortawesome/fontawesome-free-solid/faPrint';
import faStarSolid from '@fortawesome/fontawesome-free-solid/faStar';
import faStarHalf from '@fortawesome/fontawesome-free-solid/faStarHalf';
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus';
import faPencilAlt from '@fortawesome/fontawesome-free-solid/faPencilAlt';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faLocationArrow from '@fortawesome/fontawesome-free-solid/faLocationArrow';
import faBars from '@fortawesome/fontawesome-free-solid/faBars';
import faMap from '@fortawesome/fontawesome-free-solid/faMap';
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch';
import faCaretLeft from '@fortawesome/fontawesome-free-solid/faCaretLeft';
import faCaretRight from '@fortawesome/fontawesome-free-solid/faCaretRight';
import faGlobe from '@fortawesome/fontawesome-free-solid/faGlobe';
import faUserFriends from '@fortawesome/fontawesome-free-solid/faUserFriends';
import faClock from '@fortawesome/fontawesome-free-regular/faClock';
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import faFilePdf from '@fortawesome/fontawesome-free-solid/faFilePdf';
import faSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import faArrowRight from '@fortawesome/fontawesome-free-solid/faArrowRight';
import faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle';

fontawesome.library.add(
    faFacebook,
    faInstagram,
    faTwitter,
    faPinterest,
    faGooglePlus,
    faPrint,
    faStar,
    faStarSolid,
    faStarHalf,
    faPlus,
    faPencilAlt,
    faAngleRight,
    faLocationArrow,
    faBars,
    faMap,
    faSearch,
    faCaretLeft,
    faCaretRight,
    faGlobe,
    faUserFriends,
    faClock,
    faChevronDown,
    faCheck,
    faCheckCircle,
    faFilePdf,
    faSpinner,
    faArrowRight,
    faInfoCircle
);

const _bind = Symbol('_bind');
const _objectFitPolyfill = Symbol('_objectFitPolyfill');

export default class Global {
    constructor() {
        this.util = new Util();
        this.user = new User();
    }


    [_bind]() {
        $(window).scroll(function() {
            if($(window).scrollTop() > 0) {
                $('body').addClass('scrolled');
            } else {
                $('body').removeClass('scrolled');
            }
        });


        $('.c-header-search__toggle').on('click', (e) => {
            e.preventDefault();

            $('.c-header-search').toggleClass('c-header-search--open');

            if($('.c-header-search').hasClass('c-header-search--open')) {
                $('.c-header-search__input').focus();
            }
        });
    }


    /* https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3 */
    [_objectFitPolyfill]() {
        if ("objectFit" in document.documentElement.style !== false) {
            return;
        }

        $('.o-objectfit').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
              $container
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass('o-objectfit--compat');
            }  
        });
    }


    init() {
        this[_bind]();
        
        this[_objectFitPolyfill]();
        
        this.util.init();
        this.util.dotdotdot();

        this.user.init();
    }
}