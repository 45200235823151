var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (currentUser, users) {if (users.length) {
pug_html = pug_html + "\u003Ctable class=\"o-table\"\u003E\n\u003Ctr class=\"o-table__row o-table__row--header\"\u003E\n\u003Cth class=\"o-table__cell o-table__cell--header\"\u003EUser\u003C\u002Fth\u003E\n\u003Cth class=\"o-table__cell o-table__cell--header\"\u003EEmail\u003C\u002Fth\u003E\n\u003Cth class=\"o-table__cell o-table__cell--header o-table__cell--action\"\u003E\u003C\u002Fth\u003E\n\u003C\u002Ftr\u003E";
// iterate users
;(function(){
  var $$obj = users;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var user = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctr class=\"o-table__row\"\u003E\n\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = user.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = user.email) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell o-table__cell--action\"\u003E";
if (!(currentUser.id == user.id)) {
pug_html = pug_html + "\u003Ca href=\"#\" class=\"c-remove-user\" data-id=\"" + (pug.escape(null == (pug_interp = user.id) ? "" : pug_interp)) + "\"\u003ERemove\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\n\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var user = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctr class=\"o-table__row\"\u003E\n\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = user.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = user.email) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell o-table__cell--action\"\u003E";
if (!(currentUser.id == user.id)) {
pug_html = pug_html + "\u003Ca href=\"#\" class=\"c-remove-user\" data-id=\"" + (pug.escape(null == (pug_interp = user.id) ? "" : pug_interp)) + "\"\u003ERemove\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\n\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftable\u003E";
}
else {
pug_html = pug_html + "\u003Cp\u003ENo users yet.\u003C\u002Fp\u003E";
}}.call(this,"currentUser" in locals_for_with?locals_for_with.currentUser:typeof currentUser!=="undefined"?currentUser:undefined,"users" in locals_for_with?locals_for_with.users:typeof users!=="undefined"?users:undefined));;return pug_html;};
module.exports = template;