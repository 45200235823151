var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Date, quest) {pug_html = pug_html + (null == (pug_interp = require("./header.pug").call(this, locals)) ? "" : pug_interp);
function prettyDate(dateString){
{
pug_html = pug_html + "\u003C!--if it's already a date object and not a string you don't need this line:--\u003E";
var date = new Date(dateString);
var d = date.getDate();
var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
var m = monthNames[date.getMonth()];
var y = date.getFullYear();
return d+' '+m+' '+y;
}
}
var bQuestComplete = quest.users[0].pivot.complete;
pug_html = pug_html + "\u003Cdiv class=\"container\"\u003E\n\u003Cdiv class=\"account-subtitle\"\u003E\n\u003Cimg src=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = quest.image) ? "" : pug_interp)) + "\" class=\"img-fluid rounded\" alt=\"Course image\"\u003E\n\u003Cdiv class=\"c-course-bookmark rounded\"\u003E\n\u003Cdiv class=\"row\"\u003E\n\u003Cdiv class=\"col-md-3\"\u003E\n\u003Cimg src=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = quest.image) ? "" : pug_interp)) + "\" class=\"img-fluid rounded\" alt=\"Course image\"\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"col-md-9\"\u003E";
if (bQuestComplete) {
pug_html = pug_html + "\u003Cp class=\"card-text\"\u003EQuest completed on " + (pug.escape(null == (pug_interp = prettyDate(quest.users[0].pivot.completed_at)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"text-muted\"\u003ELatest Activity\u003C\u002Fp\u003E\n\u003Ch3\u003E" + (pug.escape(null == (pug_interp = quest.lessons[quest.completed_lessons].name) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\n\u003Ca href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = quest.lessons[quest.completed_lessons].id) ? "" : pug_interp)) + "\" class=\"btn btn-primary btn-lg\"\u003EContinue Day " + (pug.escape(null == (pug_interp = quest.completed_lessons + 1) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003Chr \u002F\u003E\n\u003Cdiv class=\"progress mb-2\"\u003E";
var progress = quest.completed_lessons / quest.lessons.length * 100
pug_html = pug_html + "\u003Cdiv class=\"progress-bar\" role=\"progressbar\" style=\"width: " + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "%\" aria-valuenow=\"" + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "\" aria-valuemin=\"0\" aria-valuemax=\"" + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "\"\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003Cp class=\"text-muted\"\u003E" + (pug.escape(null == (pug_interp = quest.completed_lessons) ? "" : pug_interp)) + "\u002F" + (pug.escape(null == (pug_interp = quest.lessons.length) ? "" : pug_interp)) + " days completed\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003Ch3\u003ELessons\u003C\u002Fh3\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"main-content\"\u003E\n\u003Cdiv id=\"accordion\" class=\"c-accordion\"\u003E";
// iterate quest.weeks
;(function(){
  var $$obj = quest.weeks;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var week = $$obj[pug_index0];
var activeWeekID = quest.started_lessons.length ? quest.started_lessons[quest.started_lessons.length-1].week_id : quest.weeks[0].id;
pug_html = pug_html + "\u003Cdiv class=\"card\"\u003E\n\u003Cdiv class=\"card-header\" id=\"headingOne\"\u003E\n\u003Ch5 class=\"mb-0\"\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn btn-link",(week.id == activeWeekID) ? "" : "collapsed"], [false,true]), false, true)+" data-toggle=\"collapse\""+pug.attr("data-target", "#collapse"+week.id, true, true)+" aria-expanded=\"true\" aria-controls=\"collapseOne\"") + "\u003E" + (pug.escape(null == (pug_interp = week.name) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003C\u002Fh5\u003E\n\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["collapse",(week.id == activeWeekID) ? "show" : ""], [false,true]), false, true)+pug.attr("id", "collapse"+week.id, true, true)+" aria-labelledby=\"headingOne\" data-parent=\"#accordion\"") + "\u003E\u003Cdiv class=\"card-body\"\u003E\n\u003Cdiv class=\"list-group list-group-flush\"\u003E";
var nextLessonID = bQuestComplete ? -1 : quest.lessons[quest.completed_lessons].id
// iterate week.lessons
;(function(){
  var $$obj = week.lessons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var lesson = $$obj[pug_index1];
if ((lesson.completed && lesson.completed.complete) || lesson.id == nextLessonID) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes(["list-group-item list-group-item-action",(lesson.id == nextLessonID) ? "active" : ""], [false,true]), false, true)+pug.attr("href", "/quests/"+quest.id+"/lessons/"+lesson.id, true, true)) + "\u003E\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (lesson.completed && lesson.completed.complete) {
pug_html = pug_html + "\u003Ci class=\"fas fa-check\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"list-group-item list-group-item-action\"\u003E\n\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var lesson = $$obj[pug_index1];
if ((lesson.completed && lesson.completed.complete) || lesson.id == nextLessonID) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes(["list-group-item list-group-item-action",(lesson.id == nextLessonID) ? "active" : ""], [false,true]), false, true)+pug.attr("href", "/quests/"+quest.id+"/lessons/"+lesson.id, true, true)) + "\u003E\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (lesson.completed && lesson.completed.complete) {
pug_html = pug_html + "\u003Ci class=\"fas fa-check\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"list-group-item list-group-item-action\"\u003E\n\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var week = $$obj[pug_index0];
var activeWeekID = quest.started_lessons.length ? quest.started_lessons[quest.started_lessons.length-1].week_id : quest.weeks[0].id;
pug_html = pug_html + "\u003Cdiv class=\"card\"\u003E\n\u003Cdiv class=\"card-header\" id=\"headingOne\"\u003E\n\u003Ch5 class=\"mb-0\"\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn btn-link",(week.id == activeWeekID) ? "" : "collapsed"], [false,true]), false, true)+" data-toggle=\"collapse\""+pug.attr("data-target", "#collapse"+week.id, true, true)+" aria-expanded=\"true\" aria-controls=\"collapseOne\"") + "\u003E" + (pug.escape(null == (pug_interp = week.name) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003C\u002Fh5\u003E\n\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["collapse",(week.id == activeWeekID) ? "show" : ""], [false,true]), false, true)+pug.attr("id", "collapse"+week.id, true, true)+" aria-labelledby=\"headingOne\" data-parent=\"#accordion\"") + "\u003E\u003Cdiv class=\"card-body\"\u003E\n\u003Cdiv class=\"list-group list-group-flush\"\u003E";
var nextLessonID = bQuestComplete ? -1 : quest.lessons[quest.completed_lessons].id
// iterate week.lessons
;(function(){
  var $$obj = week.lessons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var lesson = $$obj[pug_index2];
if ((lesson.completed && lesson.completed.complete) || lesson.id == nextLessonID) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes(["list-group-item list-group-item-action",(lesson.id == nextLessonID) ? "active" : ""], [false,true]), false, true)+pug.attr("href", "/quests/"+quest.id+"/lessons/"+lesson.id, true, true)) + "\u003E\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (lesson.completed && lesson.completed.complete) {
pug_html = pug_html + "\u003Ci class=\"fas fa-check\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"list-group-item list-group-item-action\"\u003E\n\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var lesson = $$obj[pug_index2];
if ((lesson.completed && lesson.completed.complete) || lesson.id == nextLessonID) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes(["list-group-item list-group-item-action",(lesson.id == nextLessonID) ? "active" : ""], [false,true]), false, true)+pug.attr("href", "/quests/"+quest.id+"/lessons/"+lesson.id, true, true)) + "\u003E\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (lesson.completed && lesson.completed.complete) {
pug_html = pug_html + "\u003Ci class=\"fas fa-check\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"list-group-item list-group-item-action\"\u003E\n\u003Cspan\u003E\n\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E\n\u003C\u002Fspan\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";}.call(this,"Date" in locals_for_with?locals_for_with.Date:typeof Date!=="undefined"?Date:undefined,"quest" in locals_for_with?locals_for_with.quest:typeof quest!=="undefined"?quest:undefined));;return pug_html;};
module.exports = template;