var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (quest, questID, user) {pug_html = pug_html + "\u003Cdiv class=\"d-flex align-items-center mb-4\"\u003E\n\u003Ch3 class=\"mb-0 mr-2\"\u003EConfirm Payment\u003C\u002Fh3\u003E\n\u003Cp class=\"mb-0 text-muted\"\u003E($" + (pug.escape(null == (pug_interp = quest.price) ? "" : pug_interp)) + ")\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003Cform action=\"stripe\u002Fcharge\" method=\"post\" id=\"payment-form\" class=\"c-stripe-form\"\u003E\n\u003Cinput type=\"hidden\" name=\"quest_id\" value=\"" + (pug.escape(null == (pug_interp = questID) ? "" : pug_interp)) + "\" \u002F\u003E\n\u003Cdiv class=\"form-errors\"\u003E\u003C\u002Fdiv\u003E";
if (!(user)) {
pug_html = pug_html + (null == (pug_interp = require("../../user/includes/register-form.pug").call(this, locals)) ? "" : pug_interp);
}
if (user && user.stripe_id) {
pug_html = pug_html + "\u003Cp\u003EYour credit card on file will be charged.\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv id=\"card-element-wrapper\"\u003E\n\u003Clabel for=\"card-element\"\u003ECredit or debit card\u003C\u002Flabel\u003E\n\u003Cdiv id=\"card-element\"\u003E\u003C!-- A Stripe Element will be inserted here. --\u003E\u003C\u002Fdiv\u003E\n\u003C!-- Used to display form errors. --\u003E\n\u003Cdiv id=\"card-errors\" role=\"alert\"\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cbutton type=\"submit\" class=\"btn btn-primary btn-lg mt-4 mr-2\"\u003EConfirm\u003C\u002Fbutton\u003E\n\u003Cbutton type=\"button\" class=\"btn btn-secondary mt-4\" data-dismiss=\"modal\"\u003ECancel\u003C\u002Fbutton\u003E\n\u003C\u002Fform\u003E";}.call(this,"quest" in locals_for_with?locals_for_with.quest:typeof quest!=="undefined"?quest:undefined,"questID" in locals_for_with?locals_for_with.questID:typeof questID!=="undefined"?questID:undefined,"user" in locals_for_with?locals_for_with.user:typeof user!=="undefined"?user:undefined));;return pug_html;};
module.exports = template;