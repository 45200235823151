import Axios from './axios';
import 'bootstrap/js/dist/modal';
import StripeWrapper from './stripe';
import User from './user';

const _bind = Symbol('_bind');
const _getQuests = Symbol('_getQuests');
const _getQuestWeeks = Symbol('_getQuestWeeks');
const _getQuestIntro = Symbol('_getQuestIntro');
const _getQuestLesson = Symbol('_getQuestLesson');
const _viewQuest = Symbol('_viewQuest');
const _startLesson = Symbol('_startLesson');
const _completeLesson = Symbol('_completeLesson');
const _completeTask = Symbol('_completeTask');
const _uncompleteTask = Symbol('_uncompleteTask');
const _completeQuest = Symbol('_completeQuest');

export default class Quest {
    constructor(args) {
      this.$quests = $('.c-quests');

      this.axios = new Axios();
      this.user = args && args.user ? args.user : new User();
      this.stripe = new StripeWrapper({
        user: this.user, 
        userInit: true, 
        quest: this,
        parent: 'quest'
      });

      this.currentQuest = null;
    }


    [_bind](id) {
      $('.c-quest-intro').on('click', '.c-start-quest', e => {
        e.preventDefault();

        console.log(this.currentQuest)

        const aPath = window.location.pathname.split('/');

        this.apiClient.get('/auth/user').then(response => {
          
          // Already paid
          if(response.data.organisation) {
            this.startQuest(id).then(response => {
              console.log(response);
              window.location.href = `/quests/${id}/weeks`;
            }).catch(response => {
              console.log(response);
              this.util.showFormErrors(form, 'Error starting quest');
            });

          } else {
            // Registered
            $('.modal-body').html(
              require('../../../views/quests/includes/modal.pug')(
                { user: response.data, questID: aPath[2], quest: this.currentQuest }
              )
            );

            $('.modal').modal({backdrop:'static'});
            $('.modal').modal('show');

            this.stripe.init();
          }
        
        }).catch(e => {
          // Not registered
          $('.modal-body').html(
            require('../../../views/quests/includes/modal.pug')(
              { questID: aPath[2], quest: this.currentQuest }
            )
          );

          $('.modal').modal({backdrop:'static'});
          $('.modal').modal('show');

          this.stripe.init();
        });
      });

      $('.c-quest-intro').on('keyup', '#userInputLicenseKey', (e) => {
        if($(e.target).val() == '') {
          $('#card-element-wrapper').show();
        } else {
          $('#card-element-wrapper').hide();
        }
      });

      $('.c-quest-lesson').on('click', '.card--task', e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const aPath = window.location.pathname.split('/');
        const iTotal = parseInt( $('.c-quest-lesson__total-tasks').text() );

        if($this.hasClass('card--task-complete')) {
          $this.removeClass('card--task-complete');

          const iCompleted = parseInt( $('.c-quest-lesson__tasks-completed').text() ) - 1;
          $('.c-quest-lesson__tasks-completed').text(iCompleted);

          $('.c-quest-lesson__complete').addClass('d-none');
  
          this[_uncompleteTask](aPath[2], aPath[4], $this.data('taskid'));
        
        } else {
          $this.addClass('card--task-complete');
          
          const iCompleted = parseInt( $('.c-quest-lesson__tasks-completed').text() ) + 1;
          $('.c-quest-lesson__tasks-completed').text(iCompleted);
          
          if(iCompleted == iTotal) $('.c-quest-lesson__complete').removeClass('d-none');

          this[_completeTask](aPath[2], aPath[4], $this.data('taskid'));
        }
      });

      $('.c-quest-lesson').on('click', '.c-quest-lesson__complete', e => {
        e.preventDefault();

        const aPath = window.location.pathname.split('/');
        const bCompleteQuest = $(e.target).hasClass('c-quest__complete');
        
        this[_completeLesson](aPath[2], aPath[4]).then(response => {
          if($(e.target).hasClass('c-quest__complete')) {
            this[_completeQuest](aPath[2]).then(response => {
              console.log(response)
              $('.c-quest-lesson__complete').parent().remove();
              $('.chapter-right').removeClass('chapter-right--disabled');
            });
          } else {
            $('.c-quest-lesson__complete').parent().remove();
            $('.chapter-right').removeClass('chapter-right--disabled');
          }
        });
      });

      $('.c-quest-lesson').on('click', '.chapter-right', e => {
        if($(e.target).hasClass('chapter-right--disabled')) {
          e.preventDefault();
          alert("You need to complete the lesson first");
        }
      });
    }


    [_getQuestWeeks](id) {
      return this.apiClient.get(`/quests/${id}`).then(response => {
        console.log(response.data.data);

        $('.c-quest-weeks').html(
          require('../../../views/quests/includes/weeks.pug')(
            { quest: response.data.data.quest }
          )
        );
      });
    }


    [_getQuestIntro](id) {
      return this.apiClient.get(`/quests/${id}`).then(response => {
        console.log(response.data.data);

        console.log(this.user.loggedIn())

        this.currentQuest = response.data.data.quest;

        if(this.user.loggedIn()) {
          this[_viewQuest](id);
        }

        $('.c-quest-intro').html(
          require('../../../views/quests/includes/intro.pug')(
            { quest: response.data.data.quest }
          )
        );
      });
    }


    [_getQuestLesson](questID, lessonID) {
      return this.apiClient.get(`/quests/${questID}/lessons/${lessonID}`).then(response => {
        console.log(response.data.data);

        const quest = response.data.data.quest;
        const lesson = response.data.data.lesson;
        const currentIndex = quest.lessons.findIndex(x => x.id === lesson.id);

        this.currentQuest = quest;

        // You can only start the next lesson directly after the one you've completed
        if(currentIndex <= quest.started_lessons.length) {
          this[_startLesson](questID, lessonID);
        }

        $('.c-quest-lesson').html(
          require('../../../views/quests/includes/lesson.pug')(
            { 
              quest: response.data.data.quest,
              lesson: response.data.data.lesson
            }
          )
        );
      });
    }


    [_getQuests]() {
      return this.apiClient.get('/quests').then(response => {
        console.log('get quests', response.data.data);

        $('.c-quests').each(function() {
          $(this).html(
            require('../../../views/includes/courses.pug')(
              { title: $(this).data('title') || '', quests: response.data.data }
            )
          );
        });
      });
    }

    [_viewQuest](id) {
      return this.apiClient.get(`/quests/${id}/view`).then(response => {
        console.log(response);
      });
    }

    [_completeQuest](id) {
      return this.apiClient.get(`/quests/${id}/complete`).then(response => {
        console.log(response);
        return response;
      });
    }

    
    [_startLesson](questID, lessonID) {
      return this.apiClient.get(`/quests/${questID}/lessons/${lessonID}/start`).then(response => {
        console.log(response);
      });
    }
    
    [_completeLesson](questID, lessonID) {
      return this.apiClient.get(`/quests/${questID}/lessons/${lessonID}/complete`).then(response => {
        console.log(response);
        return response;
      });
    }


    [_completeTask](questID, lessonID, taskID) {
      return this.apiClient.get(`/quests/${questID}/lessons/${lessonID}/tasks/${taskID}/complete`).then(response => {
        console.log(response);
      });
    }

    [_uncompleteTask](questID, lessonID, taskID) {
      return this.apiClient.get(`/quests/${questID}/lessons/${lessonID}/tasks/${taskID}/uncomplete`).then(response => {
        console.log(response);
      });
    }


    startQuest(id) {
      return this.apiClient.get(`/quests/${id}/start`).then(response => {
        console.log(response);
      });
    }


    init() {
      console.log('quests init');

      this.axios.init();
      this.apiClient = this.axios.getClient();

      const aPath = window.location.pathname.split('/');

      if(aPath.length > 2 && aPath[2] !== 'browse') {
        this[_bind](aPath[2]);

        console.log(aPath)
        
        if(aPath[3] === 'weeks') {
          this[_getQuestWeeks](aPath[2]);
        } else if(aPath[3] === 'lessons') {
          this[_getQuestLesson](aPath[2], aPath[4]);
        } else {
          this[_getQuestIntro](aPath[2]);
        }
      } else {
        this[_bind]();
        this[_getQuests]();
      }
    }
}