var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Date, lesson, quest) {Date.prototype.addDays = function(days) {
{
var date = new Date(this.valueOf());
date.setDate(date.getDate() + days);
return date;
}
}
function prettyDate(dateString){
{
pug_html = pug_html + "\u003C!--if it's already a date object and not a string you don't need this line:--\u003E";
var date = new Date(dateString);
var d = date.getDate();
var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
var m = monthNames[date.getMonth()];
var y = date.getFullYear();
return d+' '+m+' '+y;
}
}
function available(dateString, lessonNumber) {
{
var startDate = new Date(dateString).addDays(lessonNumber);
var now = new Date();
return now >= startDate;
}
}
function availableDate(dateString, lessonNumber) {
{
var availableDate = new Date(dateString).addDays(lessonNumber);
return prettyDate(availableDate.toDateString());
}
}
var currentLessonID = lesson.id;
var currentIndex = 0;
// iterate quest.lessons
;(function(){
  var $$obj = quest.lessons;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var lesson = $$obj[index];
if (lesson.id == currentLessonID) {
currentIndex = index;
}
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var lesson = $$obj[index];
if (lesson.id == currentLessonID) {
currentIndex = index;
}
    }
  }
}).call(this);

pug_html = pug_html + (null == (pug_interp = require("./header.pug").call(this, locals)) ? "" : pug_interp);
if (!available(quest.start_at, currentIndex)) {
pug_html = pug_html + "\u003Cdiv class=\"container pt-5 pb-5\"\u003E\n\u003Cdiv class=\"alert alert-primary\" role=\"alert\"\u003E\n\u003Ci class=\"fas fa-info-circle\"\u003E\u003C\u002Fi\u003E This lesson will be available on " + (pug.escape(null == (pug_interp = availableDate(quest.start_at, currentIndex)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
else
if (currentIndex > quest.started_lessons.length) {
pug_html = pug_html + "\u003Cdiv class=\"container pt-5 pb-5\"\u003E\n\u003Cdiv class=\"alert alert-primary\" role=\"alert\"\u003E\n\u003Ci class=\"fas fa-info-circle\"\u003E\u003C\u002Fi\u003E You need to complete the previous lesson first. \u003Ca" + (pug.attr("href", "/quests/"+quest.id+"/lessons/"+quest.lessons[currentIndex-1].id, true, true)) + "\u003EGo back\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"container\"\u003E\n\u003Cdiv class=\"account-subtitle\"\u003E\n\u003Ch3\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"main-content\"\u003E\n\u003Cdiv class=\"course-video\"\u003E\n\u003Ciframe src=\"https:\u002F\u002Fmedia.publit.io\u002Ffile\u002F" + (pug.escape(null == (pug_interp = lesson.video) ? "" : pug_interp)) + ".html?player=&amp;adtag=\" scrolling=\"no\" style=\"border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute; overflow:hidden;\" allowfullscreen=\"\"\u003E\u003C\u002Fiframe\u003E                \n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"course-content\"\u003E\n\u003Cp class=\"normal-paragraph\"\u003E" + (pug.escape(null == (pug_interp = lesson.description) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
if (lesson.resources && lesson.resources.length) {
pug_html = pug_html + "\u003Ch3\u003EResources\u003C\u002Fh3\u003E\n\u003Cul class=\"list-unstyled mb-5\"\u003E";
// iterate lesson.resources
;(function(){
  var $$obj = lesson.resources;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var resource = $$obj[pug_index1];
pug_html = pug_html + "\u003Cli\u003E\n\u003Ci class=\"fas fa-file-pdf\"\u003E\u003C\u002Fi\u003E\n\u003Ca href=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = resource.file) ? "" : pug_interp)) + "\" target=\"_blank\"\u003E" + (pug.escape(null == (pug_interp = resource.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\n\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var resource = $$obj[pug_index1];
pug_html = pug_html + "\u003Cli\u003E\n\u003Ci class=\"fas fa-file-pdf\"\u003E\u003C\u002Fi\u003E\n\u003Ca href=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = resource.file) ? "" : pug_interp)) + "\" target=\"_blank\"\u003E" + (pug.escape(null == (pug_interp = resource.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\n\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
if (lesson.tasks && lesson.tasks.length) {
pug_html = pug_html + "\u003Ch3 class=\"c-quest-lesson__task-meta\" data-completed=\"" + (pug.escape(null == (pug_interp = lesson.completed_tasks.length) ? "" : pug_interp)) + "\" data-total=\"" + (pug.escape(null == (pug_interp = lesson.tasks.length) ? "" : pug_interp)) + "\"\u003E\n\u003Cspan class=\"c-quest-lesson__tasks-completed\"\u003E" + (pug.escape(null == (pug_interp = lesson.completed_tasks.length) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u002F\u003Cspan class=\"c-quest-lesson__total-tasks\"\u003E" + (pug.escape(null == (pug_interp = lesson.tasks.length) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E Tasks Completed\n\u003C\u002Fh3\u003E\n\u003Cdiv class=\"row mb-5\"\u003E";
// iterate lesson.tasks
;(function(){
  var $$obj = lesson.tasks;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var task = $$obj[pug_index2];
pug_html = pug_html + "\u003Cdiv class=\"col-md-6\"\u003E\u003Ca" + (pug.attr("class", pug.classes(["card card--task" + (task.completed ? " card--task-complete" : "")], [true]), false, true)+" href=\"#\""+pug.attr("data-taskid", task.id, true, true)) + "\u003E\u003Cfigure class=\"card-figure\"\u003E\n\u003Cdiv class=\"prod-image\"\u003E\n\u003Cimg class=\"card-image\" src=\"\u002Fdist\u002Fcharity1.jpg\"\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"prod-summary\"\u003E\n\u003Ci class=\"fas fa-check-circle\"\u003E\u003C\u002Fi\u003E\n\u003Cp\u003E" + (pug.escape(null == (pug_interp = task.description) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Ffigure\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var task = $$obj[pug_index2];
pug_html = pug_html + "\u003Cdiv class=\"col-md-6\"\u003E\u003Ca" + (pug.attr("class", pug.classes(["card card--task" + (task.completed ? " card--task-complete" : "")], [true]), false, true)+" href=\"#\""+pug.attr("data-taskid", task.id, true, true)) + "\u003E\u003Cfigure class=\"card-figure\"\u003E\n\u003Cdiv class=\"prod-image\"\u003E\n\u003Cimg class=\"card-image\" src=\"\u002Fdist\u002Fcharity1.jpg\"\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"prod-summary\"\u003E\n\u003Ci class=\"fas fa-check-circle\"\u003E\u003C\u002Fi\u003E\n\u003Cp\u003E" + (pug.escape(null == (pug_interp = task.description) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Ffigure\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
if (!(lesson.completed && lesson.completed.complete)) {
pug_html = pug_html + "\u003Cp class=\"text-center\"\u003E";
var bTasksCompleted = lesson.completed_tasks.length != lesson.tasks.length;
var bLastLesson = (currentIndex + 1) == quest.lessons.length;
var sButtonText = bLastLesson ? "Mark Quest as Complete" : "Mark as Complete";
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes(["btn btn-lg btn-primary c-quest-lesson__complete" + (bTasksCompleted ? " d-none" : "") + (bLastLesson ? " c-quest__complete" : "")], [true]), false, true)+" href=\"#\" role=\"button\"") + "\u003E" + (pug.escape(null == (pug_interp = sButtonText) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"chapter-controls\"\u003E";
if (currentIndex == 0) {
pug_html = pug_html + "\u003Ca class=\"chapter-left col-md-6\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fintro\"\u003E\n\u003Cdiv class=\"chapter-content\"\u003E\n\u003Ci class=\"fas fa-caret-left\"\u003E\u003C\u002Fi\u003E\n\u003Cp\u003EIntroduction\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca class=\"chapter-left col-md-6\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = quest.lessons[currentIndex-1].id) ? "" : pug_interp)) + "\"\u003E\n\u003Cdiv class=\"chapter-content\"\u003E\n\u003Ci class=\"fas fa-caret-left\"\u003E\u003C\u002Fi\u003E\n\u003Cp\u003E" + (pug.escape(null == (pug_interp = quest.lessons[currentIndex-1].name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fa\u003E";
}
if ((currentIndex + 1) < quest.lessons.length) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes(["chapter-right col-md-6" + (lesson.completed && lesson.completed.complete ? "" : " chapter-right--disabled")], [true]), false, true)+pug.attr("href", "/quests/"+quest.id+"/lessons/"+quest.lessons[currentIndex+1].id, true, true)) + "\u003E\u003Cdiv class=\"chapter-content\"\u003E\n\u003Cp\u003E" + (pug.escape(null == (pug_interp = quest.lessons[currentIndex+1].name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003Ci class=\"fas fa-caret-right\"\u003E\u003C\u002Fi\u003E\n\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"chapter-right col-md-6\"\u003E\n\u003Cdiv class=\"chapter-content\"\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}}.call(this,"Date" in locals_for_with?locals_for_with.Date:typeof Date!=="undefined"?Date:undefined,"lesson" in locals_for_with?locals_for_with.lesson:typeof lesson!=="undefined"?lesson:undefined,"quest" in locals_for_with?locals_for_with.quest:typeof quest!=="undefined"?quest:undefined));;return pug_html;};
module.exports = template;