import Util from './util';
import User from './user';

const _bind = Symbol('_bind');
const _showErrors = Symbol('_showErrors');

export default class StripeWrapper {
  constructor(args) {
    this.$stripe = $('.c-stripe');

    this.util = new Util();
    this.user = args.user;
    this.quest = args.quest;

    this.userInit = args.userInit;
    this.parent = args.parent;
    this.hasStripe = false;
  }


  [_bind]() {

    if(this.hasStripe) {
      // Handle real-time validation errors from the card Element.
      this._card.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    }

    // Handle form submission.
    $('#payment-form').validate({
      submitHandler: (form) => {
        this.util.disableSubmit(form);

        if(this.hasStripe) {
          const data = this.util.getFormData(form);
          console.log(this.parent)
          
          if(this.parent === 'user' && data.type === 'individual') {
            // Register user and log them in
            this.user.register(data).then(response => {
              console.log(response);
            }).catch(error => this.util.showFormErrors(form, error));

          } else {
            if(data.license_key) {
              this.user.register(data).then(response => {
                console.log(response);
                // Start quest
                this.quest.startQuest(data.quest_id).then(response => {
                  console.log(response);
                  window.location.href = `/quests/${data.quest_id}/weeks`
                }).catch(response => {
                  console.log(response);
                  this.util.showFormErrors(form, 'Error starting quest');
                });
              }).catch(error => this.util.showFormErrors(form, error));
              
            } else {
              this._stripe.createToken(this._card).then((result) => {
                if (result.error) {
                  // Inform the user if there was an error.
                  var errorElement = document.getElementById('card-errors');
                  errorElement.textContent = result.error.message;
                
                } else {
                  if(data.name) {
                    // Register user and log them in
                    this.user.register(data).then(response => {
                      // Send the token to your server.
                      stripeTokenHandler(result.token);
                    
                    }).catch(error => this.util.showFormErrors(form, error));
  
                  } else {
                    // Already registered
                    // Send the token to your server, and update their account
                    stripeTokenHandler(result.token);
                  }
                }          
              });
            }            
          }
        
        } else {
          // Already registered with card on file
          const data = this.util.getFormData(form);

          $.post('/stripe/charge', { data: data }).then(response => {
            console.log(response);

            if(response.success) {
              // Start quest
              this.quest.startQuest(data.quest_id).then(response => {
                console.log(response);
                window.location.href = `/quests/${data.quest_id}/weeks`
              }).catch(response => {
                console.log(response);
                this.util.showFormErrors(form, 'Error starting quest');
              });
            
            } else {
              this.util.showFormErrors(form, response.message);
            }

          }).catch(error => {
            this.util.showFormErrors(form, error);
          });
        }
      }
    });

    // Submit the form with the token ID.
    const stripeTokenHandler = (token) => {
      // Insert the token ID into the form so it gets submitted to the server
      var form = document.getElementById('payment-form');
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);

      const data = this.util.getFormData(form);

      $.post('/stripe/charge', { data: data }).then(response => {
        console.log(response);

        if(response.success) {
          // Start quest
          this.quest.startQuest(data.quest_id).then(response => {
            console.log(response);
            window.location.href = `/quests/${data.quest_id}/weeks`;
          }).catch(response => {
            console.log(response);
            this.util.showFormErrors(form, 'Error starting quest');
          });
        
        } else {
          this.util.showFormErrors(form, response.message);
        }
      
      }).catch(error => {
        this.util.showFormErrors(form, error);
      });
    }
  }


  init() {
    console.log('stripe init');

    this.util.init();
    
    if(this.userInit) this.user.init();

    this.hasStripe = $('#card-element').length;

    if(this.hasStripe) {
      // Create a Stripe client.
      this._stripe = Stripe('pk_live_ddA8j1GwBwpI6dXnAdJREFOK00TTU3Et3o');

      // Create an instance of Elements.
      var elements = this._stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      var style = {
        base: {
          color: '#495057',
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#919191'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };

      // Create an instance of the card Element.
      this._card = elements.create('card', {style: style});

      // Add an instance of the card Element into the `card-element` <div>.
      this._card.mount('#card-element');
    }

    this[_bind]();
  }
}