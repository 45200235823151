import Axios from './axios';
import Validate from 'jquery-validation';
import Util from './util';
import Quest from './quests';
import StripeWrapper from './stripe';

const _bind = Symbol('_bind');
const _getUser = Symbol('_getUser');
const _login = Symbol('_login');
const _logout = Symbol('_logout');
const _createPasswordReset = Symbol('_createPasswordReset');
const _findPasswordReset = Symbol('_findPasswordReset');
const _resetPassword = Symbol('_resetPassword');
const _saveUser = Symbol('_saveUser');
const _getTransactions = Symbol('_getTransactions');
const _getOrgUsers = Symbol('_getOrgUsers');

export default class User {
  constructor(args) {
    this.$user = $('.c-user');

    this.axios = new Axios();
    this.util = new Util();
    this.stripe = new StripeWrapper({
      user: this, 
      userInit: false, 
      quest: new Quest({user: this}),
      parent: 'user'
    });
  }


  [_bind]() {
    $('.c-user-register, .c-stripe-form').on('change', 'input[name=type]', e => {
      $(e.target).parents('form').find('.organisation, .individual').toggleClass('d-none');
    });

    if( $('.c-user-register').length ) {
      this.stripe.init();
    }

    // $('.c-user-register').validate({
    //   submitHandler: (form) => {
    //     this.util.disableSubmit(form);

    //     this.register(this.util.getFormData(form))
    //       .then(response => this.util.enableSubmit(form))
    //       .catch(error => this.util.showFormErrors(form, error));
    //   }
    // });

    $('.c-user-login').validate({
      submitHandler: (form) => {
        this.util.disableSubmit(form);

        this[_login](this.util.getFormData(form))
          .then(response => this.util.enableSubmit(form))
          .catch(error => this.util.showFormErrors(form, error));
      }
    });

    $('.c-user-logout').click((e) => {
      e.preventDefault();

      this[_logout]();
    });

    $('.c-user-create-password-reset').validate({
      submitHandler: (form) => {
        this.util.disableSubmit(form);

        this[_createPasswordReset](this.util.getFormData(form))
          .then(response => {
            this.util.enableSubmit(form);
            this.util.showFormSuccess(form, response.data.message);

            $(form).find('.form-success').siblings().remove();
          })
          .catch(error => this.util.showFormErrors(form, error));
      }
    });

    $('.c-user-reset-password').validate({
      submitHandler: (form) => {
        this.util.disableSubmit(form);

        this[_resetPassword](this.util.getFormData(form))
          .then(response => {
            this.util.enableSubmit(form);
            this.util.showFormSuccess(form, 'Your password has been reset successfully, click <a href="/user/login/">here</a> to login.');

            $(form).find('.form-success').siblings().remove();
          })
          .catch(error => this.util.showFormErrors(form, error));
      }
    });

    $('.c-user-save').validate({
      submitHandler: (form) => {
        this.util.disableSubmit(form);

        this[_saveUser](this.util.getFormData(form))
          .then(response => this.util.enableSubmit(form))
          .catch(error => this.util.showFormErrors(form, error));
      }
    });

    $('.c-support').validate({
      submitHandler: (form) => {
        this.util.disableSubmit(form);

        $.post('/support', { data: this.util.getFormData(form) })
          .done(response => {
            if(response.success) {
              console.log('good',response);
              this.util.showFormSuccess(form, 'Sent!');
            } else {
              console.log('bad',response);
              this.util.showFormErrors(form, response.message);
            }
          })
          .fail(response => {
            console.log('bad',response);
            this.util.showFormErrors(form, 'Sending failed');
          })
          .always(() => this.util.enableSubmit(form));
      }
    });

    $('.c-organisation-users').on('click', '.c-remove-user', (e) => {
      const link = $(e.target);
      e.preventDefault();

      if(confirm('Are you sure?')) {
        return this.apiClient.post('/organisation/user/remove', {id: link.data('id')}).then(response => {
          console.log(response);

          link.parents('tr').remove();
        });
      }
    });
  }


  [_getUser]() {
    return this.apiClient.get('/auth/user').then(response => {
      console.log(response.data);

      if( $('.c-user-quests').length ) {
        $('.c-user-quests').html(
          require('../../../views/user/includes/quests.pug')(
            { user: response.data, quests: response.data.started_quests }
          )
        );
      }

      return response;
    });
  }


  [_login](details) {
    return this.apiClient.post('/auth/login', details).then(response => {
      console.log('login', response);

      const access_token = response.data.data.access_token;

      localStorage.setItem('access_token', access_token);

      return this[_getUser]().then(response => {
        let data = response.data;
        data.access_token = access_token;
        
        return $.ajax({url: '/auth/login', type: 'POST', data: JSON.stringify({data : data}), contentType: 'application/json; charset=utf-8', dataType: 'json'}).then(response => {
          if(window.location.pathname == '/user/login/' || window.location.pathname == '/user/register/') {
            window.location.href = '/user/account';
          } else {
            return response;
          }
        });
      });
    });
  }


  [_createPasswordReset](data) {
    console.log('create password reset');

    return this.apiClient.post('/password/create', data).then(response => response);
  }

  [_findPasswordReset](token) {
    console.log('find password reset');

    return this.apiClient.get(`/password/find/${token}`)
      .then(response => {
        $('.c-user-checking-token').remove();
        $('.c-user-reset-password').removeClass('d-none');
        return response;
      })
      .catch(response => {
        $('.c-user-checking-token').text('Invalid token');
        return response;
      });
  }

  [_resetPassword](data) {
    console.log('reset password', data);

    return this.apiClient.post('/password/reset', data).then(response => {
      return response;
    });
  }


  [_logout]() {
    return this.apiClient.get('/auth/logout').then(response => {
      console.log(response);

      $.get('/auth/logout').then(response => {
        localStorage.removeItem('access_token');

        window.location.href = '/';
      });
    
    }).catch(error => {
      $.get('/auth/logout').then(response => {
        localStorage.removeItem('access_token');

        window.location.href = '/';
      });
    });
  }


  [_saveUser](user) {
    if(user.hasOwnProperty('password') && user.password === '') {
      delete user.password;
    }

    console.log(user);

    return this.apiClient.post('/user', user).then(response => {
      console.log(response.data.data);

      $.post('/auth/user', { data: response.data.data }).then(response => {
        console.log(response);
      });
    });
  }


  [_getTransactions]() {
    return this[_getUser]().then(response => {
      let type = response.data.organisation ? 'organisation' : 'user';
      console.log(type)

      return this.apiClient.get(`/${type}/transactions`).then(response => {
        console.log('transactions', response.data.data);

        $('.c-transactions').html(
          require('../../../views/user/includes/transactions.pug')(
            { transactions: response.data.data, type: type }
          )
        );
      });
    });
  }


  [_getOrgUsers]() {
    return this[_getUser]().then(response => {
      const user = response.data;
      return this.apiClient.get('/organisation/users').then(response => {
        $('.c-organisation-users').html(
          require('../../../views/user/includes/organisation-users.pug')(
            { users: response.data.data, currentUser: user }
          )
        )
      });
    });
  }


  register(details) {
    return this.apiClient.post('/auth/signup', details).then(response => {
      console.log(response);

      return this[_login](details);
    });
  }


  loggedIn() {
    return localStorage.getItem('access_token') ? true : false;
  }


  init() {
    console.log('user init');

    this[_bind]();

    this.axios.init();
    this.apiClient = this.axios.getClient();

    this.util.init();

    this[_getUser]();

    const aPath = window.location.pathname.split('/');

    console.log(aPath)

    if(aPath.length > 3 && aPath[3] == 'billing') {
      console.log('billing');
      this[_getTransactions]();
    }

    if(aPath.length > 3 && aPath[3] == 'organisation') {
      console.log('billing');
      this[_getOrgUsers]();
    }

    if(aPath.length === 4 && aPath[2] === 'passwordreset') {
      this[_findPasswordReset](aPath[3]);
    }
  }
}