var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Date, quests, user) {function prettyDate(dateString){
{
pug_html = pug_html + "\u003C!--if it's already a date object and not a string you don't need this line:--\u003E";
var date = new Date(dateString);
var d = date.getDate();
var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
var m = monthNames[date.getMonth()];
var y = date.getFullYear();
return d+' '+m+' '+y;
}
}
function getMonthName(dateString) {
{
var date = new Date(dateString);
var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
var m = monthNames[date.getMonth()];
return m;
}
}
// iterate quests
;(function(){
  var $$obj = quests;
  if ('number' == typeof $$obj.length) {
    if ($$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var quest = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"card card--horizontal\"\u003E\n\u003Cdiv class=\"row no-gutters\"\u003E\n\u003Cdiv class=\"col-md-4\"\u003E\n\u003Ca href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fweeks\" class=\"card-img-link\"\u003E\n\u003Cimg src=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = quest.image) ? "" : pug_interp)) + "\" class=\"card-img\" alt=\"Course image\"\u003E\n\u003C\u002Fa\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"col-md-8\"\u003E\n\u003Cdiv class=\"card-body\"\u003E\n\u003Cdiv class=\"row\"\u003E\n\u003Cdiv class=\"col-md-10\"\u003E\n\u003Ch4 class=\"card-title mb-0\"\u003E\n\u003Ca href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fweeks\"\u003E" + (pug.escape(null == (pug_interp = quest.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\n\u003C\u002Fh4\u003E\n\u003Cp class=\"card-text text-muted\"\u003E" + (pug.escape(null == (pug_interp = user.name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
if (quest.pivot.complete) {
pug_html = pug_html + "\u003Cp class=\"card-text\"\u003EQuest completed on " + (pug.escape(null == (pug_interp = prettyDate(quest.pivot.completed_at)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-text card-day\"\u003E\n\u003Cdiv class=\"card-day__date\"\u003E\n\u003Cp class=\"card-day__day\"\u003EDay\u003C\u002Fp\u003E\n\u003Cp class=\"card-day__number\"\u003E" + (pug.escape(null == (pug_interp = quest.completed_lessons + 1) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"card-day__meta\"\u003E\n\u003Cp class=\"card-day__quest-title\"\u003E" + (pug.escape(null == (pug_interp = quest.lessons[quest.completed_lessons].name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003Cp class=\"card-day__time text-muted\"\u003E\u003Ci class=\"far fa-clock\"\u003E\u003C\u002Fi\u003E 10m\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"progress\"\u003E";
var progress = quest.completed_lessons / quest.lessons.length * 100
pug_html = pug_html + "\u003Cdiv class=\"progress-bar\" role=\"progressbar\" style=\"width: " + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "%\" aria-valuenow=\"" + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "\" aria-valuemin=\"0\" aria-valuemax=\"100\"\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003Cp class=\"card-text\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (pug.escape(null == (pug_interp = quest.completed_lessons) ? "" : pug_interp)) + " of " + (pug.escape(null == (pug_interp = quest.lessons.length) ? "" : pug_interp)) + " completed\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E";
if (quest.pivot.complete) {
pug_html = pug_html + "\u003Ca class=\"btn btn-lg btn-primary\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fintro\" role=\"button\"\u003ERevisit Quest\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca class=\"btn btn-lg btn-primary\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = quest.lessons[quest.completed_lessons].id) ? "" : pug_interp)) + "\" role=\"button\"\u003EContinue - Day " + (pug.escape(null == (pug_interp = quest.completed_lessons + 1) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (quest.pivot.complete) {
pug_html = pug_html + "\u003Cdiv class=\"col-md-2 card-date-col text-center\"\u003E\n\u003Cp\u003E\u003Csmall class=\"text-muted\"\u003EEnded on\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E\n\u003Cdiv class=\"card-date\"\u003E\n\u003Cdiv class=\"card-date__month\"\u003E" + (pug.escape(null == (pug_interp = getMonthName(quest.pivot.completed_at)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"card-date__day\"\u003E" + (pug.escape(null == (pug_interp = new Date(quest.pivot.completed_at).getDate()) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"col-md-2 card-date-col text-center\"\u003E\n\u003Cp\u003E\u003Csmall class=\"text-muted\"\u003EToday is\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E\n\u003Cdiv class=\"card-date\"\u003E\n\u003Cdiv class=\"card-date__month\"\u003EDay\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"card-date__day\"\u003E" + (pug.escape(null == (pug_interp = quest.completed_lessons + 1) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E            ";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
      }
    } else {
pug_html = pug_html + "\u003Ca class=\"btn btn-lg btn-primary\" href=\"\u002Fquests\u002Fbrowse\"\u003EBrowse Quests\u003C\u002Fa\u003E";
    }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var quest = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"card card--horizontal\"\u003E\n\u003Cdiv class=\"row no-gutters\"\u003E\n\u003Cdiv class=\"col-md-4\"\u003E\n\u003Ca href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fweeks\" class=\"card-img-link\"\u003E\n\u003Cimg src=\"https:\u002F\u002Fres.cloudinary.com\u002Fhffhrtajz\u002Fimage\u002Fupload\u002F" + (pug.escape(null == (pug_interp = quest.image) ? "" : pug_interp)) + "\" class=\"card-img\" alt=\"Course image\"\u003E\n\u003C\u002Fa\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"col-md-8\"\u003E\n\u003Cdiv class=\"card-body\"\u003E\n\u003Cdiv class=\"row\"\u003E\n\u003Cdiv class=\"col-md-10\"\u003E\n\u003Ch4 class=\"card-title mb-0\"\u003E\n\u003Ca href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fweeks\"\u003E" + (pug.escape(null == (pug_interp = quest.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\n\u003C\u002Fh4\u003E\n\u003Cp class=\"card-text text-muted\"\u003E" + (pug.escape(null == (pug_interp = user.name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
if (quest.pivot.complete) {
pug_html = pug_html + "\u003Cp class=\"card-text\"\u003EQuest completed on " + (pug.escape(null == (pug_interp = prettyDate(quest.pivot.completed_at)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-text card-day\"\u003E\n\u003Cdiv class=\"card-day__date\"\u003E\n\u003Cp class=\"card-day__day\"\u003EDay\u003C\u002Fp\u003E\n\u003Cp class=\"card-day__number\"\u003E" + (pug.escape(null == (pug_interp = quest.completed_lessons + 1) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"card-day__meta\"\u003E\n\u003Cp class=\"card-day__quest-title\"\u003E" + (pug.escape(null == (pug_interp = quest.lessons[quest.completed_lessons].name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\n\u003Cp class=\"card-day__time text-muted\"\u003E\u003Ci class=\"far fa-clock\"\u003E\u003C\u002Fi\u003E 10m\u003C\u002Fp\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"progress\"\u003E";
var progress = quest.completed_lessons / quest.lessons.length * 100
pug_html = pug_html + "\u003Cdiv class=\"progress-bar\" role=\"progressbar\" style=\"width: " + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "%\" aria-valuenow=\"" + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "\" aria-valuemin=\"0\" aria-valuemax=\"100\"\u003E\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003Cp class=\"card-text\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (pug.escape(null == (pug_interp = quest.completed_lessons) ? "" : pug_interp)) + " of " + (pug.escape(null == (pug_interp = quest.lessons.length) ? "" : pug_interp)) + " completed\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E";
if (quest.pivot.complete) {
pug_html = pug_html + "\u003Ca class=\"btn btn-lg btn-primary\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fintro\" role=\"button\"\u003ERevisit Quest\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca class=\"btn btn-lg btn-primary\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = quest.lessons[quest.completed_lessons].id) ? "" : pug_interp)) + "\" role=\"button\"\u003EContinue - Day " + (pug.escape(null == (pug_interp = quest.completed_lessons + 1) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (quest.pivot.complete) {
pug_html = pug_html + "\u003Cdiv class=\"col-md-2 card-date-col text-center\"\u003E\n\u003Cp\u003E\u003Csmall class=\"text-muted\"\u003EEnded on\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E\n\u003Cdiv class=\"card-date\"\u003E\n\u003Cdiv class=\"card-date__month\"\u003E" + (pug.escape(null == (pug_interp = getMonthName(quest.pivot.completed_at)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"card-date__day\"\u003E" + (pug.escape(null == (pug_interp = new Date(quest.pivot.completed_at).getDate()) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"col-md-2 card-date-col text-center\"\u003E\n\u003Cp\u003E\u003Csmall class=\"text-muted\"\u003EToday is\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E\n\u003Cdiv class=\"card-date\"\u003E\n\u003Cdiv class=\"card-date__month\"\u003EDay\u003C\u002Fdiv\u003E\n\u003Cdiv class=\"card-date__day\"\u003E" + (pug.escape(null == (pug_interp = quest.completed_lessons + 1) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E            ";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
    }
    if ($$l === 0) {
pug_html = pug_html + "\u003Ca class=\"btn btn-lg btn-primary\" href=\"\u002Fquests\u002Fbrowse\"\u003EBrowse Quests\u003C\u002Fa\u003E";
    }
  }
}).call(this);
}.call(this,"Date" in locals_for_with?locals_for_with.Date:typeof Date!=="undefined"?Date:undefined,"quests" in locals_for_with?locals_for_with.quests:typeof quests!=="undefined"?quests:undefined,"user" in locals_for_with?locals_for_with.user:typeof user!=="undefined"?user:undefined));;return pug_html;};
module.exports = template;