import promise from 'core-js/fn/promise';
import symbol from 'core-js/fn/symbol';

// import fontawesome from '@fortawesome/fontawesome';
// import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
// fontawesome.library.add(faAngleDown);

import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/button';

import EventEmitter from 'events';
window.ee = new EventEmitter();

import Global from './components/global';
let g = new Global();
g.init();

window.components = window.components || {};
window.app = window.app || {};

for(let sComponent in window.components) {
    let oArgs = window.components[sComponent];

    import(/* webpackChunkName: "[request]" */`./components/${sComponent}`)
        .then(component => { 
            window.app[sComponent] = new component.default(oArgs); 
            window.app[sComponent].init();

            window.ee.emit(`${sComponent}.loaded`);
        });
}

$(document).ready(function(){
    let hamburger = $('.hamburger');
    let hamburgerIcon = $('.hamburger > svg');
    let mobileMenu = $('.mobile-menu');
    let registerList = $('.mobile-dropdown-list');
    let registerLink = $('.register-dropdown');

    mobileMenu.hide();
    registerList.hide();

    hamburger.on('click', function(){
        mobileMenu.slideToggle();
        hamburgerIcon.toggleClass('fa-bars');
        hamburgerIcon.toggleClass('fa-times');
    });
    registerLink.on('click', function(){
        registerList.slideToggle();
    });
});