var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (lesson, quest) {pug_html = pug_html + "\u003Cdiv class=\"sub-header\"\u003E\n\u003Cdiv class=\"container\"\u003E\n\u003Ch1 class=\"page-title\"\u003E" + (pug.escape(null == (pug_interp = quest.name) ? "" : pug_interp)) + "\u003C\u002Fh1\u003E";
if (quest.users && quest.users.length && quest.users[0].pivot.started) {
if (quest.weeks && quest.weeks.length) {
pug_html = pug_html + "\u003Cdiv class=\"dropdown account-buttons\"\u003E\n\u003Cbutton class=\"btn btn-lg btn-primary dropdown-toggle\" type=\"button\" id=\"dropdownMenuButton\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"\u003ELessons\u003C\u002Fbutton\u003E\n\u003Cdiv class=\"dropdown-menu\" aria-labelledby=\"dropdownMenuButton\"\u003E\n\u003Ca class=\"dropdown-item\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fweeks\u002F\"\u003EOverview\u003C\u002Fa\u003E\n\u003Cdiv class=\"dropdown-divider\"\u003E\u003C\u002Fdiv\u003E\n\u003Ca class=\"dropdown-item\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Fintro\u002F\"\u003EIntroduction\u003C\u002Fa\u003E\n\u003Cdiv class=\"dropdown-divider\"\u003E\u003C\u002Fdiv\u003E";
// iterate quest.weeks
;(function(){
  var $$obj = quest.weeks;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var week = $$obj[index];
if ((lesson && week.id == lesson.week.id) || (!lesson && quest.started_lessons && quest.started_lessons.length && quest.started_lessons[quest.started_lessons.length-1].week_id == week.id) || (!lesson && !quest.started_lessons && index == 0)) {
// iterate week.lessons
;(function(){
  var $$obj = week.lessons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var lesson = $$obj[pug_index1];
if (quest.users && quest.users.length && quest.users[0].pivot.started) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = lesson.id) ? "" : pug_interp)) + "\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"dropdown-item\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var lesson = $$obj[pug_index1];
if (quest.users && quest.users.length && quest.users[0].pivot.started) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = lesson.id) ? "" : pug_interp)) + "\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"dropdown-item\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
    }
  }
}).call(this);

}
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var week = $$obj[index];
if ((lesson && week.id == lesson.week.id) || (!lesson && quest.started_lessons && quest.started_lessons.length && quest.started_lessons[quest.started_lessons.length-1].week_id == week.id) || (!lesson && !quest.started_lessons && index == 0)) {
// iterate week.lessons
;(function(){
  var $$obj = week.lessons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var lesson = $$obj[pug_index2];
if (quest.users && quest.users.length && quest.users[0].pivot.started) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = lesson.id) ? "" : pug_interp)) + "\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"dropdown-item\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var lesson = $$obj[pug_index2];
if (quest.users && quest.users.length && quest.users[0].pivot.started) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" href=\"\u002Fquests\u002F" + (pug.escape(null == (pug_interp = quest.id) ? "" : pug_interp)) + "\u002Flessons\u002F" + (pug.escape(null == (pug_interp = lesson.id) ? "" : pug_interp)) + "\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"dropdown-item\"\u003E" + (pug.escape(null == (pug_interp = lesson.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
    }
  }
}).call(this);

}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";}.call(this,"lesson" in locals_for_with?locals_for_with.lesson:typeof lesson!=="undefined"?lesson:undefined,"quest" in locals_for_with?locals_for_with.quest:typeof quest!=="undefined"?quest:undefined));;return pug_html;};
module.exports = template;