import axios from 'axios';

const _setup = Symbol('_setup');

export default class Axios {
    constructor(args) {
      this._client = axios.create();
    }


    [_setup]() {
      const hostname = window.location.hostname;
      const protocol = hostname.includes('local') ? 'http://' : 'https://';

      // this._client.defaults.baseURL = `${protocol}${hostname}/api/`;
      this._client.defaults.baseURL = hostname.includes('local') ?
        'https://indigenous-mindnz-backend.local/api/' :
        'https://api.ingeniusminds.com/api/';
      this._client.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
      this._client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

      // let token = document.head.querySelector('meta[name="csrf-token"]');

      // if (token) {
      //   this._client.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
      // } else {
      //   console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
      // }

      this._client.interceptors.request.use(
          function(config) {
              const token = localStorage.getItem('access_token');

              config.headers['Authorization'] = 'Bearer ' + token;

              return config;
          }, 
          function(error) {
              console.log(error);
              return Promise.reject(error);
          });

      this._client.interceptors.response.use(
          function (response) {
              return response;
          }, 
          function (error) {
              console.log(error)
              if(error.response && error.response.status === 401) {
                  localStorage.removeItem('access_token');

                  if(window.location.pathname != '/user/login/') {
                    window.location.href = '/';
                  }
              }
              return Promise.reject(error);
          });
    }


    getClient() {
      return this._client;
    }


    init() {
      console.log('axios init');

      this[_setup]();
    }
}