var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (transactions, type) {if (transactions.length) {
pug_html = pug_html + "\u003Ctable class=\"o-table\"\u003E\n\u003Ctr class=\"o-table__row o-table__row--header\"\u003E";
if (type == 'organisation') {
pug_html = pug_html + "\u003Cth class=\"o-table__cell o-table__cell--header\"\u003ELicense\u003C\u002Fth\u003E";
}
else {
pug_html = pug_html + "\u003Cth class=\"o-table__cell o-table__cell--header\"\u003EQuest\u003C\u002Fth\u003E";
}
pug_html = pug_html + "\u003Cth class=\"o-table__cell o-table__cell--header\"\u003EDate\u003C\u002Fth\u003E\n\u003Cth class=\"o-table__cell o-table__cell--header\" style=\"width: 100px;\"\u003EAmount\u003C\u002Fth\u003E\n\u003Cth class=\"o-table__cell o-table__cell--header o-table__cell--action\"\u003E\u003C\u002Fth\u003E\n\u003C\u002Ftr\u003E";
// iterate transactions
;(function(){
  var $$obj = transactions;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var transaction = $$obj[pug_index0];
if (transaction.stripe_paid) {
pug_html = pug_html + "\u003Ctr class=\"o-table__row\"\u003E";
if (type == 'organisation') {
pug_html = pug_html + "\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.license.license_type.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.quest.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.created_at) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.stripe_amount) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell o-table__cell--action\"\u003E\u003Ca href=\"" + (pug.escape(null == (pug_interp = transaction.stripe_receipt_url) ? "" : pug_interp)) + "\" target=\"_blank\"\u003EView\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\n\u003C\u002Ftr\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var transaction = $$obj[pug_index0];
if (transaction.stripe_paid) {
pug_html = pug_html + "\u003Ctr class=\"o-table__row\"\u003E";
if (type == 'organisation') {
pug_html = pug_html + "\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.license.license_type.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.quest.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.created_at) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell\"\u003E" + (pug.escape(null == (pug_interp = transaction.stripe_amount) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\n\u003Ctd class=\"o-table__cell o-table__cell--action\"\u003E\u003Ca href=\"" + (pug.escape(null == (pug_interp = transaction.stripe_receipt_url) ? "" : pug_interp)) + "\" target=\"_blank\"\u003EView\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\n\u003C\u002Ftr\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftable\u003E";
}
else {
pug_html = pug_html + "\u003Cp\u003ENo transactions yet.\u003C\u002Fp\u003E";
}}.call(this,"transactions" in locals_for_with?locals_for_with.transactions:typeof transactions!=="undefined"?transactions:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return pug_html;};
module.exports = template;